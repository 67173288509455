import "bootstrap/dist/css/bootstrap.min.css";

import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import {
  Box,
  Building,
  FileCheck,
  GraphUp,
  Hammer,
  People,
  House
} from "react-bootstrap-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { getObject, storeData } from "../services/storage.service";
import { DisplayBasedOnRole } from "./DisplayBasedOnRole";

// import socketIO from 'socket.io-client';

// const socket = socketIO.connect('http://localhost:3333');

// socket.on("message",data=> console.log(data));

// function IOMessages()
// {

//   useEffect(() => {
    
  
//     return () => {
      
//     }
//   }, [])
  

//   return(<></>)
// }

export default function Header() {
  const navigate = useNavigate();

  const [userInformation] = useState({ ...getObject("jwt_token") });

  const logout = () => {
    storeData("jwt_token", null);
    navigate("/app/login");
  };
  return (
    <>
      <Navbar style={{ backgroundColor: "#415e9a" }} variant="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#home">
            <img
              style={{ marginRight: 10 }}
              src="/logo.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="iTeloma Logo"
            />
            iTeloma{" "}
            {userInformation?.user?.mine && (
              <> - {userInformation?.user?.mine?.name} </>
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav className="me-auto">
            <NavLink className="nav-link" to="/">
                  <House /> Home
                </NavLink>
              <DisplayBasedOnRole roles={["Admin", "Mine","OEM"]}>
                <NavLink className="nav-link" to="/app/inspections">
                  <FileCheck /> Inspections
                </NavLink>
              </DisplayBasedOnRole>
              <DisplayBasedOnRole roles={["Admin"]}>
                <NavLink className="nav-link" to="/app/users">
                  <People /> Users
                </NavLink>
              </DisplayBasedOnRole>
              <DisplayBasedOnRole roles={["Admin", "Inspector"]}>
                <NavLink className="nav-link" to="/app/mines">
                  {" "}
                  <Building /> Mines
                </NavLink>
              </DisplayBasedOnRole>
              <DisplayBasedOnRole roles={["Admin", "Inspector", "Mine","Repairman"]}>
                <NavLink className="nav-link" to="/app/scsr">
                  {" "}
                  <Box /> SCSR
                </NavLink>
              </DisplayBasedOnRole>
              <DisplayBasedOnRole roles={["Admin", "Repair","Repairman"]}>
                <NavLink className="nav-link"  to="/app/repairs">
                  <Hammer />
                  RFQs Oders
                </NavLink>
              </DisplayBasedOnRole>
              <DisplayBasedOnRole roles={["Admin","Repair","OEM"]}>
                <NavLink className="nav-link"  to="/app/reports">
                  <GraphUp /> Reports
                </NavLink>
              </DisplayBasedOnRole>
            </Nav>
            <Navbar.Text>
              Signed in as: {userInformation?.user?.name},{" "}
              <a href="#login" onClick={logout}>
                Logout !
              </a>
              ,
            </Navbar.Text>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
