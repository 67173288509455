import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { Card, DatePicker, Radio, Select } from "antd";
import { httpClient } from "../services/http.service";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const { RangePicker } = DatePicker;

export default function SearchBar(props) {
  const [dateRange, setdateRange] = useState();
  const [value, setvalue] = useState("");
  return (
    <>
      <div style={{ width: props?.width || "100%", marginBottom: "20px" }}>
        {props.useDate && (
          <>
            <RangePicker onChange={(e) => setdateRange(e)} />{" "}
            <Button
              disabled={!dateRange}
              onClick={(e) => props.search(dateRange)}
              size="sm"
              style={{ marginLeft: "10px" }}
            >
              Search
            </Button>
          </>
        )}
        <InputGroup className="mb-3">
          <FormControl
            disabled={props.disabled}
            placeholder={props.placeholder}
            aria-describedby="basic-addon2"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (props.search) props.search(value);
              }
            }}
            onChange={(e) => {
              var value = e.target.value;
              setvalue(value);
              // if (value.length > 3)
              //     props.search(value);
            }}
          />
          <Button
            variant="primary"
            onClick={(e) => {
              if (props.search) props.search(value);
            }}
            id="button-addon2"
          >
            <Search />
          </Button>
        </InputGroup>
      </div>
    </>
  );
}

export function SearchBarWithTimeRange(props) {
  const [dateRange, setdateRange] = useState();

  return (
    <>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <RangePicker onChange={(e) => setdateRange(e)} />{" "}
        <Button
          disabled={!dateRange}
          onClick={(e) => props.search(dateRange)}
          size="sm"
          style={{ marginLeft: "10px" }}
        >
          Search
        </Button>
      </div>
    </>
  );
}

export function SearchBarWithTimeRangeSelect({
  search,
  selectPlaceHolder,
  selectOptions,
  selectPlaceHolder1,
  selectOptions1,
  buttonTitle,
  printEnable,
  printHandler,
}) {
  const [dateRange, setdateRange] = useState();
  const [selectedSelctItem, setselectedSelctItem] = useState();
  const [selectedSelctItem2, setselectedSelctItem2] = useState();
  return (
    <>
      <Card style={{ width: "100%", margin: "10px" }}>
        <div>

          
          <Select
            mode="multiple"
            style={{ margin: "5px", width: "100%" }}
            onChange={(e) => setselectedSelctItem2(e)}
            placeholder={selectPlaceHolder1}
            options={selectOptions1}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          ></Select>
        </div>
        <Select
          style={{ margin: "5px", width: "300px" }}
          onChange={(e) => {
            
            
            setselectedSelctItem(e)
          }}
          placeholder={selectPlaceHolder}
          options={selectOptions}
        ></Select>
        <RangePicker
          format={"YYYY-MM-DD"}
          onChange={(e) => {
            let tempe = e;

            //  tempe[0] = moment(tempe[0]).startOf('day');
            //   tempe[1] = moment(tempe[0]).endOf('day');

            setdateRange(e);
          }}
        />{" "}
        <Button
          disabled={!dateRange}
          onClick={(e) =>
            search(dateRange, selectedSelctItem, selectedSelctItem2)
          }
          size="sm"
          style={{ marginLeft: "10px" }}
        >
          {buttonTitle}
        </Button>
        {printEnable && (
          <Button
            style={{ marginLeft: "10px" }}
            onClick={printHandler}
            size="sm"
          >
            Print
          </Button>
        )}
      </Card>
    </>
  );
}

export function SearchMinesWithDateRange({ search }) {
  const [dateRange, setdateRange] = useState();

  const [selectedMine, setselectedMine] = useState();

  const [mines, setmines] = useState([]);

  const [selectedMode, setselectedMode] = useState(1);

  useEffect(() => {
    httpClient
      .get("/api/v1/mines")
      .then((responsr) => {
        const { data } = responsr;
        setmines([...data]);
      })
      .catch((Err) => {});
    return () => {};
  }, []);

  return (
    <>
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <Radio.Group
          onChange={(e) => setselectedMode(e.target.value)}
          value={selectedMode}
        >
          <Radio value={1}>Search</Radio>
          <Radio value={2}>Inspection Download</Radio>
        </Radio.Group>
        {selectedMode===1 && <>
            <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={mines?.map((mine) => ({
            label: mine?.name,
            value: mine?._id,
          }))}
          style={{ margin: "2px", width: "400px" }}
          onChange={(e) => setselectedMine(e)}
          placeholder="Select Mine"
        >
          {mines?.map((mine) => (
            <Select.Option value={mine?._id}>{mine?.name}</Select.Option>
          ))}
        </Select>
        </>}
        
        <RangePicker
          style={{ margin: "2px" }}
          onChange={(e) => setdateRange(e)}
        />{" "}
        <Button
          disabled={!dateRange}
          onClick={(e) => search(dateRange, selectedMine,selectedMode)}
          size="sm"
          style={{ margin: "2px" }}
        >
          Search
        </Button>
      </div>
    </>
  );
}
